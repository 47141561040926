<template>
  <div>
    <b-card>
      <b-card-body>
        <div>
          <div>
            <div class="logo-wrapper">
              <h3 class="text-primary">
                {{ $t("message.params.systemParameters") }}
              </h3>
            </div>
          </div>
        </div>
      </b-card-body>

      <!-- Spacer -->
      <hr class="invoice-spacing" />

      <b-col cols="12">
        <b-form-group
          label-for="profit-marging"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_USER'"
        >
          <template v-slot:label>
            {{ $t("message.params.profitMargin") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="PercentIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="profit-marging"
              type="number"
              v-model="paramList.profitMargin"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-for="note-tax"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_USER'"
        >
          <template v-slot:label>
            {{ $t("message.params.tax") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="PercentIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="note-tax"
              type="number"
              v-model="paramList.noteTax"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-for="hours-month"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_USER'"
        >
          <template v-slot:label>
            {{ $t("message.params.hoursOfTheMonth") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ClockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="hours-month"
              type="number"
              v-model="paramList.hoursMonth"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.maximumSizeOfList") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="AlignLeftIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="list-size"
              type="number"
              v-model="paramList.tableSize"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.maximumNumberOfCards") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SquareIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="list-size"
              type="number"
              v-model="paramList.cardSize"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            {{ $t("sessionTimeToken") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ClockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="list-size"
              type="number"
              v-model="paramList.timeExpireAccessToken"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            {{ $t("sessionTimeDefault") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ClockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="list-size"
              type="number"
              v-model="paramList.timeExpireRefreshToken"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("modalTime") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ClockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="list-size"
              type="number"
              v-model="paramList.timeToExpireModalToken"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            {{ $t("dataLimit") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SquareIcon" />
            </b-input-group-prepend>
            <b-form-input id="list-size" type="number" v-model="dataLimitMb" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            {{ $t("maxSizeFile") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="FilePlusIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="list-size"
              type="number"
              v-model="paramList.maxFileSize"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            {{ $t("cookiesTime") }}
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ClockIcon" />
            </b-input-group-prepend>
            <b-form-input id="list-size" v-model="paramList.cookiesTime" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label> Zenvia token </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ShieldIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="paramList.zenviaToken" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label> Comtele token </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ShieldIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="paramList.comteleToken" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label> Email do root </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ShieldIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="paramList.rootEmail" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label> Telefone do root </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ShieldIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="paramList.rootPhone" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("typeFile") }}
          </template>
          <v-select
            multiple
            v-model="paramList.fileTypes"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fileTypesList"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="team-role"
          />
        </b-form-group>
      </b-col>

      <!--<b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.automaticCommunication") }}
          </template>
          <v-select
            multiple
            v-model="paramList.communicationTypes"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="communicationTypesList"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="team-role"
          />
        </b-form-group>
      </b-col>-->

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            {{ $t("message.params.defaultUser") }}
          </template>
          <v-select
            v-model="paramList.defaultUser"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="userOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="team-role"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            Tipos de comunicação para lembretes
          </template>
          <v-select
            multiple
            v-model="paramList.pointCommunicationType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="communicationTypesList"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="team-role"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            Tempo de atraso para enviar lembrete de ponto (em minutos)
          </template>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ClockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="list-size"
              type="number"
              v-model="paramList.pointCommunicationMinutes"
            />
          </b-input-group>
        </b-form-group>
      </b-col>      

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            Lembrete de bater ponto
          </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            v-model="paramList.pointCommunication"
          />
        </b-form-group>
      </b-col>      

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.listOrder") }}
          </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            v-model="listSortBoolean"
            @input="changeListOrder(listSortBoolean)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_USER'"
        >
          <template v-slot:label>
            {{ $t("message.params.sendEmails") }}
          </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            v-model="paramList.sendEmail"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_USER'"
        >
          <template v-slot:label>
            {{ $t("reminder_show") }}
          </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            v-model="paramList.reminder"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label> {{ $t("message.params.tour") }} </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            v-model="paramList.tour"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="userData.systemRole === 'ROLE_ADMIN'">
        <b-form-group label-for="list-size" label-cols-md="4">
          <template v-slot:label>
            {{ $t("message.params.showRegisterOrganization") }}
          </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            v-model="paramList.organization"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.otpSms") }}
          </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            v-model="paramList.otpSms"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.otpEmail") }}
          </template>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            v-model="paramList.otpEmail"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.updateProjects") }}
          </template>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="success"
            class="mr-1"
            @click="updateProjectData()"
          >
            {{ $t("message.buttons.update") }}
          </b-button>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label-for="list-size"
          label-cols-md="4"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          <template v-slot:label>
            {{ $t("message.params.clearCache") }}
          </template>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="danger"
            class="mr-1"
            @click="resetCacheData()"
          >
            {{ $t("message.list.clear") }}
          </b-button>
        </b-form-group>
      </b-col>
      <br />

      <!-- submit and reset -->
      <b-col class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="updateData('ADMIN')"
          v-if="userData.systemRole === 'ROLE_ADMIN'"
        >
          {{ $t("message.buttons.save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="updateData('USER')"
          v-else
        >
          {{ $t("message.buttons.save") }}
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BCard,
    BCardBody,
    vSelect,
  },

  directives: {
    Ripple,
    money: VMoney,
  },

  data() {
    return {
      paramList: [],
      //CurrentUser
      userData: store.state.user.userData,

      listSortBoolean: true,

      communicationTypesList: [],
      fileTypesList: [],
      userOptions: [],
      dataLimitMb: "",
    };
  },

  async created() {
    await axios
      .get(`${URL_API}param`, { headers: getHeader(this.userData) })
      .then((response) => {
        if (response.data.length > 0) {
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].tenant === this.userData.tenant) {
              this.paramList = response.data[i];
            }
          }
          if (this.paramList.listSort === "asc" || !this.paramList.listSort) {
            this.listSortBoolean = false;
          }
          var sizeInMB = (this.paramList.dataLimit / (1024 * 1024)).toFixed(2);
          this.dataLimitMb = sizeInMB;
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}notification_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.communicationTypesList.push({
            label: typeList[i],
            value: typeList[i],
          });
        }
      });

    var fileTypeList = [];
    await axios
      .get(`${URL_API}file_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          fileTypeList.push(element);
          this.fileTypesList.push({
            label: fileTypeList[i],
            value: fileTypeList[i],
          });
        }
      });

    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].email;
          userList.push(element);
          userId.push(id);
          this.userOptions.push({ label: userList[i], value: userId[i] });
        }
      });

    var none = true;
    if (this.paramList.tenant === this.userData.tenant) {
      none = false;
    }

    if (none) {
      var param = {};

      param.noteTax = 25;
      param.profitMargin = 30;
      param.hoursMonth = 160;
      param.tableSize = 10;
      param.cardSize = 13;
      param.sessionTimeDefault = 30;
      param.sendEmail = true;
      param.tour = true;
      param.organization = true;
      param.listSort = "desc";
      param.communicationTypes = [];
      param.fileTypes = [];
      param.maxFileSize = 20;
      param.timeExpireAccessToken = 10;
      param.timeExpireRefreshToken = 30;
      param.timeToExpireModalToken = 30;
      param.dataLimit = 200;
      param.reminder = true;
      param.otp = false;
      param.zenviaToken = "";
      param.comteleToken = "";
      param.cookiesTime = "1m";

      await axios({
        method: "post",
        url: `${URL_API}param`,
        headers: getHeader(this.userData),
        data: param,
      }).then(() => {
        axios
          .get(`${URL_API}param`, { headers: getHeader(this.userData) })
          .then((response) => {
            if (response.data.length > 0) {
              this.paramList = response.data[0];
            }
          });
      });
    }
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    updateData(val) {
      this.paramList.dataLimit = Number(this.dataLimitMb);

      var type = false;
      if (val === "ADMIN") {
        type = true;
      }

      axios({
        method: "put",
        url: `${URL_API}param/${this.paramList.id}/${type}`,
        headers: getHeader(this.userData),
        data: this.paramList,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.saved"),
              icon: "CheckIcon",
              variant: "success",
              text: `Parâmetros atualizados com sucesso`,
            },
          });
        })
        .then(() => {
          //paramData global
          localStorage.setItem("paramData", JSON.stringify(this.paramList));
        });
    },

    changeListOrder(order) {
      if (order === false) {
        this.paramList.listSort = "asc";
      } else {
        this.paramList.listSort = "desc";
      }
    },

    updateProjectData() {
      axios({
        method: "get",
        url: `${URL_API}project/update/all/${this.userData.tenant}`,
        headers: getHeader(this.userData),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("projectUpdate"),
          },
        });
      });
    },

    resetCacheData() {
      axios({
        method: "get",
        url: `${URL_API}cache/reset`,
        headers: getHeader(this.userData),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("cacheClean"),
          },
        });
      });
    },
  },
};
</script>
